const productCategory = [
  {
    id: 1,
    label: "Groceries",
    value: "groceries",
    subcategories: [
      { id: 101, label: "Biscuits & Drinks", value: "biscuits, drinks" },
      { id: 102, label: "Fruits & Vegetables", value: "fruits, vegetables" },
      { id: 103, label: "Dry Fish", value: "dry fish" },
      { id: 104, label: "Cooking Essentials", value: "cooking essentials" },
      { id: 105, label: "Dairy & Bakery", value: "dairy, bakery" },
      { id: 106, label: "Mom & Baby Care", value: "mom, baby care" },
      { id: 107, label: "Disposables", value: "disposables" },
    ],
  },
  {
    id: 2,
    label: "Kitchenware",
    value: "kitchenware",
    subcategories: [
      { id: 201, label: "Cookers & Steamers", value: "cookers, steamers" },
      { id: 202, label: "Kitchen Tools", value: "kitchen tools" },
      { id: 203, label: "Pots & Pans", value: "pots, pans" },
      { id: 204, label: "Containers & Storage", value: "containers, storage" },
      {
        id: 205,
        label: "Flask, Bottle & Tiffin Boxes",
        value: "flask, bottle, tiffin boxes",
      },
      { id: 206, label: "Cutting & Chopping", value: "cutting, chopping" },
    ],
  },
  {
    id: 3,
    label: "Toys & Games",
    value: "toys, games",
    subcategories: [
      {
        id: 301,
        label: "Bikes, Trikes & Ride-Ons",
        value: "bikes, trikes, ride-ons",
      },
      {
        id: 302,
        label: "Remote & App-Controlled Toys",
        value: "remote, app-controlled toys",
      },
      { id: 302, label: "Toy Vehicles", value: "toy vehicles" },
      { id: 302, label: "Model Building Kits", value: "model building kits" },
    ],
  },
  {
    id: 4,
    label: "Stationary",
    value: "stationary",
    subcategories: [

    ],
  },
  {
    id: 5,
    label: "Electronics",
    value: "electronics",
    subcategories: [
    ],
  },
  {
    id: 6,
    label: "Home Decor",
    value: "home decor",
    subcategories: [
    ],
  },
  {
    id: 7,
    label: "Home Care",
    value: "home care",
    subcategories: [
    ],
  },
  {
    id: 8,
    label: "Personal Care",
    value: "personal care",
    subcategories: [
      { id: 801, label: "Hair Care", value: "hair care" },
      { id: 802, label: "Skin Care", value: "skin care" },
      { id: 803, label: "Oral Care", value: "oral care" },
      { id: 804, label: "Men's Grooming", value: "men's grooming" },
      { id: 805, label: "Deo & Fragrances", value: "deo , fragrances" },
      { id: 806, label: "Health & Wellness", value: "health, wellness" },
    ],
  },
  {
    id: 9,
    label: "Gifts & Hampers",
    value: "gifts, hampers",
    subcategories: [
      { id: 901, label: "Tea Gifts", value: "tea gifts" },
      { id: 902, label: "Chocolate Gifts", value: "chocolate gifts" },
      { id: 903, label: "Gourmet Gifts", value: "gourmet gifts" },
    ],
  },
  {
    id: 10,
    label: "Garments",
    value: "garments",
    subcategories: [],
  },
  {
    id: 11,
    label: "Health Care",
    value: "health care",
    subcategories: [],
  },
  {
    id: 12,
    label: "Non Veg",
    value: "non veg",
    subcategories: [],
  },
  {
    id: 13,
    label: "Vegitables",
    value: "vegitables",
    subcategories: [],
  },
  {
    id: 14,
    label: "Fruits",
    value: "fruits",
    subcategories: [],
  },
  {
    id: 15,
    label: "Dairy",
    value: "dairy",
    subcategories: [],
  },
  {
    id: 16,
    label: "Furniture",
    value: "furniture",
    subcategories: [],
  },
  {
    id: 17,
    label: "Bags",
    value: "bags",
    subcategories: [],
  },
  {
    id: 18,
    label: "Sports",
    value: "sports",
    subcategories: [],
  },
  {
    id: 19,
    label: "Food",
    value: "food",
    subcategories: [],
  },
];

export default productCategory;
